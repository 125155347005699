<template>
    <div :value="value">
        <el-select v-model="src_proj" filterable remote reserve-keyword placeholder="Enter SRID for searching" :remote-method="searchSrid"
            :loading="loading">
            <el-option v-for="item in options" v-bind:key="item.name" :label="item.name"
                :value="item.name"></el-option>
        </el-select>
    </div>
</template>
  
<script>

export default {
    props: ["value"],
    data() {
        return {
            default: [
                {
                    "srid": "4326",
                    "name": "EPSG:4326",
                    "unit": "degree",
                    "proj4text": "+proj=longlat +datum=WGS84 +no_defs "
                }, {
                    "srid": "3857",
                    "name": "EPSG:3857",
                    "unit": "metre",
                    "proj4text": "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs"
                }
            ],
            options: [

            ],
            loading: false,
            src_proj: '',
        };
    },
    components: {

    },
    mounted() {
        this.src_proj = this.value;
        this.options = this.default;
    },
    methods: {
        searchSrid(srid) {
            this.loading = true;
            if (srid.length <= 2) {
                this.options = this.default;
            } else {
                this.options = window.config.allprojs.filter(
                    x => (x.srid.indexOf(srid) === 0 && x.srid.length - srid.length <= 1));
            }
            this.loading = false;

        },
    },
    watch: {
        src_proj(newval) {
            this.$emit("input", newval)
        },
        value(newval){
            if(this.src_proj !== newval){
                this.src_proj = newval
            }
        },
    },
};
</script>
<style scoped></style>
  
  