<template>
  <div id="app">
    <el-container>
      <div class="aside">
        <el-card class="card">
          <el-form size="small">
            <el-form-item>
              <el-button-group>
                <el-button :type="type === 'WKT' ? 'primary' : ''" @click="type = 'WKT'">WKT</el-button>
                <el-button :type="type === 'GEOJSON' ? 'primary' : ''" @click="type = 'GEOJSON'">GeoJSON</el-button>
                <el-button :type="type === 'POINTS' ? 'primary' : ''" @click="transform_points">Point</el-button>
              </el-button-group>
            </el-form-item>

            <el-form-item :label="'Original ' + type">
              <el-input type="textarea" v-model="src_wkt" rows="8" v-if="type === 'WKT'"></el-input>
              <el-input type="textarea" v-model="src_geojson" rows="8" v-if="type === 'GEOJSON'"></el-input>
              <el-input type="textarea" v-model="src_points" rows="8" v-if="type === 'POINTS'"
                placeholder=" Format is Longitude Latitude, for multiple points please separate them by line breaks, for example: 102 33"></el-input>
              <span style="font-size:12px;color:#409EFF" v-if="type !== 'POINTS'">copy {{ type }} to preview by
                click【Location】</span>
            </el-form-item>
            <el-form-item>
              <el-col class="line" :span="2">From</el-col>
              <el-col :span="9">
                <ProjSelect v-model="src_proj" />
              </el-col>
              <el-col class="line" :span="4" style="text-align:center">Go To</el-col>
              <el-col :span="9">
                <ProjSelect v-model="dest_proj" />
              </el-col>
              <span style="font-size:12px;color:#409EFF">Support manually entering SRID CODE for searching</span>
            </el-form-item>
            <el-form-item :label="'Converted' + type">
              <el-input type="textarea" v-model="dest_wkt" rows="8" v-if="type === 'WKT'" readonly></el-input>
              <el-input type="textarea" v-model="dest_geojson" rows="8" v-if="type === 'GEOJSON'" readonly></el-input>
              <el-input type="textarea" v-model="dest_points" rows="8" v-if="type === 'POINTS'" readonly></el-input>
            </el-form-item>
            <el-button type="primary" @click="transform">Convert</el-button>
            <el-button @click="updatePolygon">Location</el-button>
          </el-form>
        </el-card>
        <el-card class="card">
          <div slot="header" class="clearfix">
            <span>Map Position</span>
            <el-button style="float: right; padding: 3px 0" type="text" v-if="!pickMode"
              @click="coordinatePicker">Coordinate Picking</el-button>
            <el-button style="float: right; padding: 3px 0" type="danger" v-if="pickMode"
              @click="cancelCoordinatePicker">Stop Picking</el-button>
            <el-button style="float: right; padding: 3px 0;margin-right: 10px;" type="text" @click="gotoMyPosition(false)">My
              Position</el-button>
          </div>
          <div>
            <el-form>
              <el-form-item>
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-select placeholder="Z" v-model="zxy_location_z">
                      <el-option v-for="i in 20" :label="i" :value="i" v-bind:key="i"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <el-input placeholder="Z/X/Y" v-model="zxy_location"></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" @click="gotoXYZPos">Tile Pos</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-row :gutter="20">
                  <el-col :span="18">
                    <el-input placeholder="Longitude, Latitude" v-model="location"></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" @click="gotoPos">Coord Pos</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </div>

        </el-card>

      </div>
      <div class="main">
        <MapView class="map" @drawend="drawend" @drawcancel="drawcancel" @mapinit="mapinit" @pickend="pickend"
          ref="map" />
      </div>
      <div class="about">
        <el-link type="primary" href="/about">About Tool</el-link>
      </div>
    </el-container>
  </div>
</template>

<script>
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import MapView from '@/components/MapView.vue'
import ProjSelect from '@/components/ProjSelect.vue'
import GeometryCollection from 'ol/geom/GeometryCollection'
import Feature from 'ol/Feature';
import { addProjection, get, transform } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import axios from 'axios';

export default {
  metaInfo: {
    title: 'GeoToolBox-Home',
    meta: [
      {
        name: "keywords",
        content: ["Map Drawing", "WKT String", "GeoJSON", "Coordinate Conversion", "Geolocation"]
      }, {
        name: "description",
        content: "Explore the world of geospatial data with our powerful toolbox. Convert coordinates, draw shapes, and explore maps with ease. Join us now!"
      }
    ]
  },
  data() {
    return {
      src_features: [],
      dest_features: [],
      src_wkt: '',
      dest_wkt: '',
      src_geojson: '',
      dest_geojson: '',
      location: '',
      zxy_location: '',
      zxy_location_z: 5,
      src_points: '',
      dest_points: '',
      pickMode: false,
      type: 'WKT',
      src_proj: 'EPSG:4326',
      dest_proj: "EPSG:3857",
      my_location: undefined,
    };
  },
  components: { MapView, ProjSelect },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    mapinit() {
      this.gotoMyPosition(true);
    },
    gotoMyPosition(init) {
      this.getMyLocation(() => {
        this.$refs.map.setCenterAndZ(this.my_location, init ? 5 : 12);
      });
    },
    getMyLocation(callback, errorcallback) {
      callback = callback || function () { }
      errorcallback = errorcallback || function () { }
      if (this.my_location) {
        callback(this.my_location)
      } else {
        axios.get("https://get.geojs.io/v1/ip/geo.json")
          .then(response => {
            let resp = response.data;
            this.my_location = transform([resp.longitude, resp.latitude], "EPSG:4326", "EPSG:3857");
            callback(this.my_location)
          }).catch(() => {
            errorcallback()
          });
      }
    }
    ,
    features2wkt(features) {
      var wktFormat = new WKT();
      let wkt = wktFormat.writeFeatures(features);
      return wkt
    },
    features2geojson(features) {
      let geojsonFormat = new GeoJSON();
      let geojson = geojsonFormat.writeFeaturesObject(features);
      return geojson
    },
    wkt2features(wkt) {
      let wktFormat = new WKT();
      let features = wktFormat.readFeatures(wkt);
      return this.splitFeatures(features)
    },
    splitFeatures(features) {
      if (features.length > 1 || features.length === 0) {
        return features;
      }
      let firstGeom = features[0].getGeometry();
      if (firstGeom instanceof GeometryCollection) {
        // 需要分拆
        let geometries = firstGeom.getGeometries();
        let newfeatures = [];
        for (let geom of geometries) {
          newfeatures.push(new Feature(geom));
        }
        return newfeatures;
      }
      return features
    },
    geojson2features(geojson) {
      let geojsonFormat = new GeoJSON();
      let features = geojsonFormat.readFeatures(geojson);
      return this.splitFeatures(features)
    },
    drawend(feature) {
      let newfeature = feature.clone();
      newfeature.getGeometry().transform("EPSG:3857", "EPSG:4326");
      this.src_features.push(newfeature);
      this.src_proj = "EPSG:4326";
    },
    pickend(feature) {
      let newfeature = feature.clone();
      newfeature.getGeometry().transform("EPSG:3857", "EPSG:4326");
      this.location = newfeature.getGeometry().flatCoordinates.join(",")
    },
    drawcancel() {
      this.src_features = []
    },
    updateLocation(geom) {
      let center = this.calculateCenterAndZoom_3857(geom).center;
      this.location = center.join(",")
    },
    ToXYZTile(pos, zoom) {
      var originShift = 2 * Math.PI * 6378137 / 2.0;
      var tileSize = 256;
      var res = originShift / (tileSize * Math.pow(2, zoom));
      var mx = pos[0];
      var my = pos[1];
      var tx = Math.floor((mx + originShift) / res / tileSize / 2);
      var ty = Math.floor(Math.pow(2, zoom) - (my + originShift) / res / tileSize / 2);
      return [zoom, tx, ty];
    },
    coordinatePicker() {
      this.pickMode = true
      this.$refs.map.pickPoint();
    },
    cancelCoordinatePicker() {
      this.pickMode = false
      this.$refs.map.cancelPickPoint();
    },
    showXYZValue(center, forceZ) {
      let center3857 = transform(center, "EPSG:4326", "EPSG:3857");
      let z = this.$refs.map.getCurZoom();
      if (forceZ) {
        z = this.zxy_location_z;
      } else {
        this.zxy_location_z = z;
      }
      let zxy = this.ToXYZTile(center3857, z)
      this.zxy_location = zxy.join("/")
    },
    gotoPos() {
      let latlon = this.location.split(",")
      let lat = parseFloat(latlon[0])
      let lon = parseFloat(latlon[1])

      let wkt = `POINT (${lat} ${lon})`
      this.src_wkt = wkt
      this.src_proj = "EPSG:4326"
      setTimeout(() => {
        this.updatePolygon();
      }, 100)

    },
    gotoXYZPos() {
      let zxy = this.zxy_location.split("/")
      let z = zxy[0]
      let x = zxy[1]
      let y = zxy[2]

      let tileSize = 256;
      let initialResolution = 2 * Math.PI * 6378137 / tileSize;
      let originShift = 2 * Math.PI * 6378137 / 2.0;

      let resolution = initialResolution / Math.pow(2, z);
      let west = x * tileSize * resolution - originShift;
      let north = originShift - y * tileSize * resolution;
      let east = west + tileSize * resolution;
      let south = north - tileSize * resolution;

      let xmin = Math.max(west, -originShift);
      let ymin = Math.max(south, -originShift);
      let xmax = Math.min(east, originShift);
      let ymax = Math.min(north, originShift);

      // let pos_3857 = [(xmin + xmax) / 2, (ymin + ymax) / 2];
      let pos_left_bottom = transform([xmin, ymin], "EPSG:3857", "EPSG:4326");
      let pos_right_top = transform([xmax, ymax], "EPSG:3857", "EPSG:4326");

      let wkt = `POLYGON ((${pos_left_bottom[0]} ${pos_left_bottom[1]}, ${pos_left_bottom[0]} ${pos_right_top[1]}, ${pos_right_top[0]} ${pos_right_top[1]}, ${pos_right_top[0]} ${pos_left_bottom[1]}, ${pos_left_bottom[0]} ${pos_left_bottom[1]}))`
      this.src_wkt = wkt
      this.src_proj = "EPSG:4326"
      setTimeout(() => {
        this.updatePolygon();
      }, 100)

    },
    transform_points() {
      this.type = "POINTS"
      this.dest_Wkt = ""
      this.src_proj = "EPSG:4326"
    },
    checkProj(proj) {
      if (!get(proj)) {
        let find = window.config.allprojs.find(x => x.name === proj)
        if (find) {
          proj4.defs(find.name, find.proj4text);
          register(proj4)
          let p = get(find.name);
          addProjection(p);
        }
      }
    },
    transform() {
      this.dest_features = [];
      for (let feature of this.src_features) {
        let newfeature = feature.clone();
        newfeature.getGeometry().transform(this.src_proj, this.dest_proj);
        this.dest_features.push(newfeature);
      }
    },
    updatePolygon() {
      let web_features = [];
      for (let feature of this.src_features) {
        let newfeature = feature.clone();
        if (this.src_proj !== "EPSG:3857") {
          newfeature.getGeometry().transform(this.src_proj, "EPSG:3857");
        }
        web_features.push(newfeature);
      }
      this.$refs.map.showFeatures(web_features);

    },
    isPoint(geom) {
      return geom.features[0].geometry.type === "Point"
    },
    isLineString(geom) {
      return geom.features[0].geometry.type === "LineString"
    },
  },
  watch: {
    src_proj() {
      this.checkProj(this.src_proj);
    },
    dest_proj() {
      this.checkProj(this.dest_proj);
    },
    src_features(newval) {
      this.src_wkt = this.features2wkt(newval);
      this.src_geojson = JSON.stringify(this.features2geojson(newval), null, 2);
    },
    dest_features(newval) {
      this.dest_wkt = this.features2wkt(newval);
      this.dest_geojson = JSON.stringify(this.features2geojson(newval), null, 2);
    },
    src_wkt(newval) {
      if (newval) {
        this.src_features = this.wkt2features(newval);
      }

    },
    dest_geojson(newval) {
      if (newval) {
        let geom = JSON.parse(newval)
        if (geom.features.map(f => f.geometry).findIndex(g => g.type !== "Point") === -1) {
          this.dest_points = geom.features.map(f => f.geometry).filter(g => g.type === "Point")
            .map(g => g.coordinates).map(c => c.join(" ")).join("\n")
        }
      }

    },
    src_geojson(newval) {
      if (newval) {
        this.src_features = this.geojson2features(newval);
      }

    },
    zxy_location_z() {
      let latlon = this.location.split(",")
      let lat = parseFloat(latlon[0])
      let lon = parseFloat(latlon[1])
      this.showXYZValue([lat, lon], true);
    },
    location() {
      let latlon = this.location.split(",")
      let lat = parseFloat(latlon[0])
      let lon = parseFloat(latlon[1])
      this.showXYZValue([lat, lon]);

    },
    src_points(newVal) {
      let points = newVal.split("\n");
      this.src_wkt = "GEOMETRYCOLLECTION(" + points.map(x => "POINT(" + x + ")").join(",") + ")"
    },
  },
};
</script>
<style scoped>
.map {
  position: absolute;
  left: 0px;
  right: 460px;
  top: 0px;
  bottom: 0px;
}

.aside {
  position: absolute;
  width: 450px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.about{
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 5px 20px;
  background: rgba(255, 255, 255, 0.521);
  }
</style>

<style></style>
