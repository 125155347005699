<template>
    <div id="app">
        <div id="map"></div>
        <div class="buttons">
            <el-button-group>
                <el-button :type="drawtype === 'Point' ? 'primary' : ''" size="small" @click="drawShape('Point')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path
                            d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z" />
                    </svg>
                </el-button>
                <el-button :type="drawtype === 'LineString' ? 'primary' : ''" size="small" @click="drawShape('LineString')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path
                            d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z" />
                    </svg></el-button>
                <el-button :type="drawtype === 'Polygon' ? 'primary' : ''" size="small" @click="drawShape('Polygon')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path
                            d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z" />
                    </svg></el-button>
                <el-button size="small" @click="cancelDraw()" v-if="drawtype !== ''">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path
                            d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z" />
                    </svg>
                </el-button>
            </el-button-group>
        </div>
        <div class="layermanager">
            <el-card class="box-card" v-if="show_layer_manager">
                <div>
                    <span>Layer Management</span>
                    <el-button style="float: right; padding: 3px 0" type="text">
                        <span @click="showAddLayerBox()">Add Layer</span>
                    </el-button>
                </div>
                <div>
                    <div style="height:15px"></div>
                    <div v-for="(layer, index) in layers" :key="index" class="text item">
                        <el-checkbox v-model="layers[index].show">{{ layer.name }}</el-checkbox>
                        &nbsp;&nbsp;<i class="el-icon-delete" v-if="!layer.base" @click="deleteLayer(layer.name)"
                            style="cursor:pointer"></i>&nbsp;&nbsp;
                        <i class="el-icon-edit" v-if="!layer.base" @click="editLayer(layer.name)"
                            style="cursor:pointer"></i>
                    </div>
                    <div style="height:35px"></div>
                </div>
            </el-card>
            <div class="layer-button">
                <el-button icon="el-icon-s-grid" circle type="primary" v-if="show_layer_manager === false"
                    @click="show_layer_manager = true"></el-button>
                <el-button icon="el-icon-close" circle type="primary" v-else
                    @click="show_layer_manager = false"></el-button>
            </div>
            <div class="issue">
                <el-button icon="el-icon-s-comment" circle type="primary" @click="openForIssue()"></el-button>
            </div>
        </div>
        <el-dialog title="Add New Layer" :visible.sync="addLayerBoxVisible" :close-on-click-modal="false">
            <el-form>
                <el-form-item label="Name">
                    <el-input v-model="newlayer.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Type">
                    <el-radio v-model="newlayer.type" label="raster">XYZ Layer</el-radio>
                </el-form-item>
                <el-form-item label="动态图层子类型" v-if="newlayer.type === 'titiler'">
                    <el-radio v-model="newlayer.subtype" label="cog">COG</el-radio>
                    <el-radio v-model="newlayer.subtype" label="mosaic">镶嵌</el-radio>
                </el-form-item>
                <el-form-item label="URL (only supports XYZ style)" v-if="newlayer.type === 'raster'">
                    <el-input v-model="newlayer.url" autocomplete="off"></el-input>
                </el-form-item>
                <el-alert v-if="addLayerError" :title="addLayerError" type="error" effect="dark" close-text="I got it!">
                </el-alert>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addLayerBoxVisible = false">Cancel</el-button>
                <el-button type="primary" @click="addLayer">Confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import { XYZ, Vector as VectorSource } from 'ol/source'
import Draw from 'ol/interaction/Draw';
import { Circle, Fill, Stroke, Style } from 'ol/style.js';
import { createEmpty, getCenter, extend } from 'ol/extent'
import axios from 'axios';
import { transformExtent, transform } from 'ol/proj';
import Attribution from "ol/control/Attribution"


export default {
    name: "MapView",
    data() {
        return {
            map: undefined,
            vector_layer: undefined,
            vector_source: undefined,
            draw: undefined,
            pick_vector_layer: undefined,
            pick_vector_source: undefined,
            pick_draw: undefined,
            drawtype: '',
            addLayerBoxVisible: false,
            addLayerError: "",
            vector_style: [
                {
                    name: 'LineString',
                    style: new Style({
                        stroke: new Stroke({
                            color: 'red',
                            width: 3,
                        })
                    })
                },
                {
                    name: 'Point',
                    style: new Style({
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({ color: 'rgba(255, 0, 0, 0.3)' }),
                            stroke: new Stroke({
                                color: 'red', width: 2
                            })
                        })
                    })
                },
                {
                    name: 'Polygon',
                    style: new Style({
                        stroke: new Stroke({
                            color: 'red',
                            width: 3,
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 0, 0, 0.3)',
                        }),
                    })
                },
            ],
            layers: [
                {
                    name: "OSM",
                    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                    show: true,
                    base: true,
                },
            ],
            newlayer: {
                name: '',
                url: '',
                type: 'raster',
                subtype: 'cog',
                extra_params: '',
            },
            show_layer_manager: false,
            initMode: true,
        };
    },
    mounted() {
        this.initMap();
        let layers = this.loadLayerFromLocalStorage();
        if (layers) {
            this.layers = this.layers.concat(layers);
        }

        setTimeout(() => {
            this.initMode = false;
        }, 5000)

    },
    methods: {
        openForIssue() {
            window.open('https://github.com/lixiaofei123/GeoToolBox_Issue/issues', '_blank');
        },
        initMap() {
            this.vector_source = new VectorSource({ wrapX: false });
            this.vector_layer = new VectorLayer({
                source: this.vector_source,
                style: this.styleFunction,
                zIndex: 2
            });
            this.pick_vector_source = new VectorSource({ wrapX: false });
            this.pick_vector_layer = new VectorLayer({
                source: this.pick_vector_source,
                style: this.styleFunction,
                zIndex: 2
            });

            this.map = new Map({
                layers: [
                    new TileLayer({
                        id: "OSM",
                        extent: [-20037508.3427892, -20037508.3427892, 20037508.3427892, 20037508.3427892],
                        source: new XYZ({
                            attributions: '© <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
                            minZoom: 0,
                            maxZoom: 18,
                            url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                            tileSize: [256, 256]
                        })
                    }
                    ),
                    this.vector_layer,
                    this.pick_vector_layer
                ],
                target: 'map',
                view: new View({
                    center: [-12402258.601479787, 7320447.435917069],
                    zoom: 5,

                }),
            });

            this.map.addControl(new Attribution({
                collapsible: false
            }));

            this.$emit("mapinit")

        },
        getCurZoom() {
            return parseInt(this.map.getView().getZoom())
        },
        styleFunction(feature) {
            return this.vector_style.find(s => feature.getGeometry().getType().indexOf(s.name) !== -1).style
        },
        setCenter(location) {

            setTimeout(() => {
                this.map.getView().animate({
                    center: location,
                    duration: 1200
                })
            }, 100)

        },
        setCenterAndZ(location, z) {
            setTimeout(() => {
                this.map.getView().animate({
                    center: location,
                    duration: 1200,
                    zoom: z,
                })
            }, 100)

        },
        showFeatures(features) {
            this.vector_source.clear();

            if (features.length > 0) {
                this.vector_source.addFeatures(features);

                let extent = createEmpty();
                features.forEach((feature) => {
                    extend(extent, feature.getGeometry().getExtent());
                });

                let center = getCenter(extent);
                let view = this.map.getView();
                let size = this.map.getSize();
                let resolution = view.getResolutionForExtent(extent, size)
                let zoom = view.getZoomForResolution(resolution);
                if (features.length === 0 && features[0].getGeometry().getType() === 'Point') {
                    this.setCenter(center);
                } else {
                    this.setCenterAndZ(center, zoom - 2);
                }
            }



        },
        pickPoint() {

            this.cancelDraw()

            if (this.pick_draw) {
                this.map.removeInteraction(this.pick_draw);
            }

            this.pick_draw = new Draw({
                source: this.pick_vector_source,
                type: "Point",
            });

            this.pick_draw.on("drawstart", () => {
                this.pick_vector_source.clear();
            })

            this.pick_draw.on("drawend", e => {
                this.$emit('pickend', e.feature)
            })

            this.map.addInteraction(this.pick_draw);

        },
        cancelPickPoint() {
            if (this.pick_draw) {
                this.map.removeInteraction(this.pick_draw);
                this.pick_draw = undefined;
                this.pick_vector_source.clear();
            }
        },
        drawShape(type) {

            this.cancelPickPoint();

            if (this.draw) {
                this.map.removeInteraction(this.draw);
            }

            this.draw = new Draw({
                source: this.vector_source,
                type: type,
            });
            this.draw.on("drawend", e => {
                this.$emit('drawend', e.feature)
            })

            this.map.addInteraction(this.draw);
            this.drawtype = type;
        },
        cancelDraw() {
            this.drawtype = ''
            if (this.draw) {
                this.map.removeInteraction(this.draw);
                this.draw = undefined;
                this.vector_source.clear();
            }
            this.$emit('drawcancel')
        },
        showAddLayerBox() {
            this.addLayerError = "";
            this.newlayer.name = "";
            this.newlayer.url = "";
            this.newlayer.type = 'raster';
            this.newlayer.subtype = 'cog';
            this.newlayer.extra_params = "";
            this.addLayerBoxVisible = true;
        },
        addLayer() {
            if (!this.newlayer.name || !this.newlayer.url) {
                this.addLayerError = "Layer name and URL cannot be empty.";
                return
            }
            let findIndex = this.layers.findIndex(x => x.name === this.newlayer.name)
            if (findIndex === -1) {
                this.layers.push(
                    {
                        ...this.newlayer,
                        show: true,
                    }
                )
            } else {
                // 更新数据
                this.$set(this.layers, findIndex, this.newlayer);
            }
            this.addLayerBoxVisible = false;

        },
        deleteLayer(name) {
            let index = this.layers.findIndex(layer => layer.name === name);
            if (index !== -1) {
                this.layers.splice(index, 1)
            }
        },
        editLayer(name) {
            let index = this.layers.findIndex(layer => layer.name === name);
            this.newlayer = {
                ...this.layers[index]
            }
            this.addLayerBoxVisible = true
        },
        updateLayerToLocalStorate() {
            let layers = this.layers.filter(layer => !layer.base);
            window.localStorage.setItem("layers", JSON.stringify(layers))
        },
        loadLayerFromLocalStorage() {
            let layers_str = window.localStorage.getItem("layers");
            if (layers_str) {
                return JSON.parse(layers_str)
            }
        },
        compareLayerDiff(metalayer) {
            let url = metalayer.url;
            if (metalayer.type === 'titiler') {
                let titiler_url = window.config.titiler_url;
                url = `${titiler_url}/${metalayer.subtype}/tile/{z}/{x}/{y}.png?url=${metalayer.url}&${metalayer.extra_params}`
            }

            let maplayer = this.map.getLayers().getArray().find(x => x.get("id") === metalayer.name);
            if (maplayer) {
                let mshow = maplayer.getVisible();
                let murl = maplayer.getSource().getUrls()[0];
                return {
                    show: metalayer.show !== mshow,
                    url: url !== murl,
                    maplayer: maplayer
                }
            }


        },
        getLayerMeta(metalayer, callback, failback) {
            callback = callback || function () { }
            failback = failback || function () { }
            let titiler_url = window.config.titiler_url;
            let metaurl = `${titiler_url}/${metalayer.subtype}/meta?url=${metalayer.url}`;
            axios.get(metaurl)
                .then(response => {
                    let resp = response.data;
                    let minzoom = resp.minzoom;
                    let bbox = resp.epsg_4326_bbox || resp.bounds;
                    let center3857 = transform([(bbox.left + bbox.right) / 2, (bbox.top + bbox.bottom) / 2], 'EPSG:4326', 'EPSG:3857');
                    let extent3857 = transformExtent([bbox.left, bbox.bottom, bbox.right, bbox.top], 'EPSG:4326', 'EPSG:3857');
                    callback({
                        minzoom: minzoom,
                        center: center3857,
                        extent: extent3857,
                    })

                })
                .catch(error => {
                    failback(error);
                });

        },
        addNewLayer(metalayer) {
            if (metalayer.type === 'raster') {
                this.map.addLayer(new TileLayer({
                    id: metalayer.name,
                    visible: metalayer.show,
                    extent: [-20037508.3427892, -20037508.3427892, 20037508.3427892, 20037508.3427892],
                    zIndex: 1,
                    source: new XYZ({
                        attributions: '',
                        minZoom: 0,
                        maxZoom: 18,
                        url: metalayer.url,
                        tileSize: [256, 256]
                    })
                }))
            } else if (metalayer.type === 'titiler') {
                let titiler_url = window.config.titiler_url;
                // 添加titiler图层
                // 首先需要根据meta信息查询数据范围
                let tileurl = `${titiler_url}/${metalayer.subtype}/tile/{z}/{x}/{y}.png?url=${metalayer.url}&${metalayer.extra_params}`;
                this.getLayerMeta(metalayer, meta => {
                    this.map.addLayer(new TileLayer({
                        id: metalayer.name,
                        visible: metalayer.show,
                        extent: meta.extent,
                        zIndex: 1,
                        source: new XYZ({
                            attributions: '',
                            minZoom: 0,
                            maxZoom: 18,
                            url: tileurl,
                            tileSize: [256, 256]
                        })
                    }))

                    if (!this.initMode) {
                        if (meta.minzoom) {
                            this.setCenterAndZ(meta.center, meta.minzoom + 1);
                        } else {
                            this.map.getView().fit(meta.extent, { center: meta.center, duration: 1200 });
                        }
                    }
                })
            }
        },
    },
    watch: {
        layers: {
            handler: function () {
                let maplayers = this.map.getLayers();
                let metalayers = this.layers;
                maplayers.forEach(maplayer => {
                    if (maplayer) {
                        let id = maplayer.get("id");
                        if (id) {
                            let findindex = metalayers.findIndex(x => x.name === id);
                            if (findindex === -1) {
                                this.map.removeLayer(maplayer);
                            }
                        }
                    }
                })
                metalayers.forEach(metalayer => {
                    let findIndex = maplayers.getArray().findIndex(x => x.get("id") === metalayer.name);
                    if (findIndex === -1) {
                        this.addNewLayer(metalayer);
                    }
                })
                metalayers.forEach(metalayer => {
                    let diff = this.compareLayerDiff(metalayer);
                    if (diff) {
                        if (diff.url && !metalayer.base) {
                            this.map.removeLayer(diff.maplayer);
                            this.addNewLayer(metalayer);
                        } else if (diff.show) {
                            diff.maplayer.setVisible(metalayer.show);
                            if (metalayer.type === 'titiler' && metalayer.show) {
                                this.getLayerMeta(metalayer, meta => {
                                    if (meta.minzoom) {
                                        this.setCenterAndZ(meta.center, meta.minzoom + 1);
                                    } else {
                                        this.map.getView().fit(meta.extent, { center: meta.center, duration: 1200 });
                                    }
                                })
                            }
                        }
                    }

                })

                this.updateLayerToLocalStorate();
            },
            deep: true
        }
    },
};
</script>
<style scoped>
#map {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.buttons {
    position: absolute;
    right: 50px;
    top: 12px;
}

.layermanager {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.issue {
    position: absolute;
    right: 60px;
    bottom: 10px;
}

.layermanager .box-card {
    width: 260px;
}

.layermanager .layer-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.item {
    margin-bottom: 6px;
}
</style>

